import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'
import LinkOrAction from './LinkOrAction'
import { usePalette } from 'react-palette'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

const SectionTwitter = ({ theme, block, isAdmin, section_id }) => {
    let content = null

    switch (block.type) {
        case 'tweet':
            content = <TwitterTweetEmbed tweetId={block.referenceId} />
            break
        case 'profile':
            content = <TwitterTimelineEmbed sourceType="profile" screenName={block.referenceId} options={{ height: 400 }} />
            break
        case 'timeline':
            content = <TwitterTimelineEmbed sourceType="profile" screenName={block.referenceId} options={{ height: 400 }} />
            break
        case 'share':
            content = <TwitterShareButton url={block.shareUrl} options={{ text: block.shareContent, via: block.referenceId }} />
            break
        case 'mention':
            break
        case 'hashtag':
            break
        case 'follow':
            break
        case 'moment':
            break
        case 'directmessage':
            break
        case 'video':
            break
        case 'onair':
            break
        case 'timeline':
            break
    }

    return (
        <section
            id={section_id}
            className="block hero-block bg-accent outer"
            style={{
                overflow: 'hidden',
            }}
        >
            {isAdmin && (
                <Link
                    style={{ position: 'absolute', top: 16, right: 16 }}
                    to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}
                >
                    <FaEdit /> Edit
                </Link>
            )}

            {content}
        </section>
    )
}

export default SectionTwitter
