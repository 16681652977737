import Img from 'gatsby-image'
import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import { FaEdit } from 'react-icons/fa'

const SectionRecentPosts = ({ block, isAdmin, section_id }) => {
    return (
        <section id={section_id} className="block features-block bg-null outer">
            {isAdmin && (
                <div style={{ textAlign: 'end' }}>
                    <Link to={`https://app.contentful.com/spaces/fsmkvylf1g83/entries/${block.contentful_id}`}>
                        <FaEdit /> Edit
                    </Link>
                </div>
            )}
            <div className="inner">
                <div className="grid">
                    {block.post.map((value, index) => (
                        <div className="cell block-content-triple">
                            <Img style={{ width: 326, height: 226 }} alt={block.title} fluid={value.heroImage.fluid} />
                            <h4 className="primary-text" style={{ textTransform: 'uppercase', maxLines: 2 }}>
                                <a href={`/${block.node_locale}/news/${value.slug}`}>{value.title}</a>
                            </h4>
                            <hr className={'hr-primary'} style={{ marginTop: 20, marginBottom: 12, height: 3, width: 60 }} />
                            <div className="block-copy">
                                <div
                                    style={{ minHeight: 100, maxLines: 3 }}
                                    dangerouslySetInnerHTML={{
                                        __html: value.description.childMarkdownRemark.html,
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default SectionRecentPosts
